<template>
  <div class="booth">
    <b-overlay :show="pb1"
               rounded="sm"
               spinner-variant="primary"
               spinner-type="grow"
               spinner-small
               opacity="0.5"
               class="overlay"
    >
      <b-card
          :img-src="require(`@/assets/pb_part.png`)"
          :bg-variant="pb1 ? 'dark' : 'white'"
          :text-variant="pb1 ? 'white' : 'dark'"
          title="#1"
      >
        <b-card-text><span v-if="pb1">사용중</span></b-card-text>
      </b-card>
    </b-overlay>
    <b-overlay :show="pb2"
               rounded="sm"
               spinner-variant="primary"
               spinner-type="grow"
               spinner-small
               opacity="0.5"
               class="overlay"
    >
      <b-card
          :img-src="require(`@/assets/pb_part.png`)"
          :bg-variant="pb2 ? 'dark' : 'white'"
          :text-variant="pb2 ? 'white' : 'dark'"
          title="#2"
      >
        <b-card-text><span v-if="pb2">사용중</span></b-card-text>
      </b-card>
    </b-overlay>
    <b-overlay :show="pb3"
               rounded="sm"
               spinner-variant="primary"
               spinner-type="grow"
               spinner-small
               opacity="0.5"
               class="overlay"
    >
      <b-card
          :img-src="require(`@/assets/pb_part.png`)"
          :bg-variant="pb3 ? 'dark' : 'white'"
          :text-variant="pb3 ? 'white' : 'dark'"
          title="#3"
      >
        <b-card-text><span v-if="pb3">사용중</span></b-card-text>
      </b-card>
    </b-overlay>
    <b-overlay :show="pb4"
               rounded="sm"
               spinner-variant="primary"
               spinner-type="grow"
               spinner-small
               opacity="0.5"
               class="overlay"
    >
      <b-card
          :img-src="require(`@/assets/pb_part.png`)"
          :bg-variant="pb4 ? 'dark' : 'white'"
          :text-variant="pb4 ? 'white' : 'dark'"
          title="#4"
      >
        <b-card-text><span v-if="pb4">사용중</span></b-card-text>
      </b-card>
    </b-overlay>
    <b-overlay :show="pb5"
               rounded="sm"
               spinner-variant="primary"
               spinner-type="grow"
               spinner-small
               opacity="0.5"
               class="overlay"
    >
      <b-card
          :img-src="require(`@/assets/pb_part.png`)"
          :bg-variant="pb5 ? 'dark' : 'white'"
          :text-variant="pb5 ? 'white' : 'dark'"
          title="#5 (사무실 안쪽)"
      >
        <b-card-text><span v-if="pb5">사용중</span></b-card-text>
      </b-card>
    </b-overlay>
    <b-overlay :show="pb6"
               rounded="sm"
               spinner-variant="primary"
               spinner-type="grow"
               spinner-small
               opacity="0.5"
               class="overlay"
    >
      <b-card
          :img-src="require(`@/assets/pb_part.png`)"
          :bg-variant="pb6 ? 'dark' : 'white'"
          :text-variant="pb6 ? 'white' : 'dark'"
          title="#6 (사무실 안쪽)"
      >
        <b-card-text><span v-if="pb6">사용중</span></b-card-text>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  data() {
    return {
      connection: null,
      pb1: false,
      pb2: false,
      pb3: false,
      pb4: false,
      pb5: false,
      pb6: false,
    }
  },
  created: function () {
    let vm = this
    
    console.log("Fetch init DynamoDB status")
    fetch("https://zng9skzpph.execute-api.ap-northeast-2.amazonaws.com/api/status")
      .then(response => response.json())
      .then(data => {
        vm.pb1 = data.pb1 === 'active'
        vm.pb2 = data.pb2 === 'active'
        vm.pb3 = data.pb3 === 'active'
        vm.pb4 = data.pb4 === 'active'
        vm.pb5 = data.pb5 === 'active'
        vm.pb6 = data.pb6 === 'active'
      })

    console.log("Starting connection to WebSocket Server")
    vm.connection = new WebSocket("wss://8615xba2mf.execute-api.ap-northeast-2.amazonaws.com/api/")

    vm.connection.onmessage = function (event) {
      let sensor = JSON.parse(event.data)
      vm.pb1 = sensor.pb1 === 'active'
      vm.pb2 = sensor.pb2 === 'active'
      vm.pb3 = sensor.pb3 === 'active'
      vm.pb4 = sensor.pb4 === 'active'
      vm.pb5 = sensor.pb5 === 'active'
      vm.pb6 = sensor.pb6 === 'active'
    }

    vm.connection.onopen = function (event) {
      console.log(event)
      console.log("Successfully connected to the echo websocket server...")
      vm.connection.send('hello')
    }

    setInterval(function () {
      console.log(vm.connection)
      vm.connection.send('hello')
    }, 5000)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.booth {
  display: grid;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 0;
  flex-basis: 25%;
  flex-shrink: 1;
  align-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  row-gap: 2em;
}

.overlay {
  max-width: 320px
}
</style>
