<template>
  <div id="app">
    <div class="logo">
      <img class="logo-img" alt="Wanted logo" src="./assets/wanted.png">
    </div>
    <WhoBooth msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import WhoBooth from './components/WhoBooth.vue'

export default {
  name: 'App',
  components: {
    WhoBooth
  }
}
</script>

<style>
#app {
  font-family: Pretendard, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.logo {
  display: flex;
  flex-shrink: 1;
  align-content: center;
  justify-content: center;
}

.logo-img {
  max-width: 100%;
}
</style>
